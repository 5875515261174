<template>
	<div class="container" v-loading='loading'>
		<div class="top" style="background: #fff;padding: 15px;">
			<div class="filter-container">
				<div class="filter-item" style="display: flex;align-items: center;">
					<label class="label">关键字: </label>
					<el-input type="text" style="width: 300px;" v-model="searchKey" placeholder="手机号/微信昵称/姓名"></el-input>
				</div>
			</div>
			<div class="filter-container">
				<div class="filter-item">
					<label class="label">变更类型: </label>
					<el-select v-model="Cause" style="width: 150px;margin-right: 10px;">
						<el-option :value="null" label="全部"></el-option>
						<el-option v-for="item in changeCauseList" :key="item.Value" :label="item.Name" :value="item.Value"></el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<label class="label">变更时间: </label>
					<el-date-picker type="datetime" placeholder="选择日期" v-model="startTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
					 format="yyyy-MM-dd HH:mm:ss" default-time='00:00:00'></el-date-picker> ~
					<el-date-picker type="datetime" placeholder="选择日期" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
					 format="yyyy-MM-dd HH:mm:ss" default-time='23:59:59'></el-date-picker>
					<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsSearch">查询</el-button>
					<button-permissions :datas="'ysbalancedetailExport'">
						<el-button style="margin-left:20px;width: 90px;"  @click="exportFun">导出</el-button>
					</button-permissions>
				</div>
			</div>
		</div>
		<div style="background: #f5f5f5;padding: 15px;font-size: 14px;"><span>本金充值金额：{{RecordStatistics.RechargePrincipalTotal}}</span><span style="margin: 0 25px;">赠送金发放总额：{{RecordStatistics.GiftMoneyGrantTotal}}</span><span>使用余额支付总额：{{RecordStatistics.UseBalancePayTotal}}</span></div>
		<div class="table-container" style="background: #fff;">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading">
				<el-table-column prop="AddTime" label="客户">
					<template slot-scope="scope">
						<div>{{scope.row.Name ? scope.row.Name : scope.row.WxNickname}}</div>
						<div v-if='scope.row.Phone'>{{scope.row.Phone}}</div>
						<div v-else>--</div>
					</template>
				</el-table-column>
				<el-table-column prop="RecordTypeValue" label="变更类型"></el-table-column>
				<el-table-column prop="BalanceChange" label="变更本金">
					<template slot-scope="scope">
						<div v-if="scope.row.BalanceChange==0">{{scope.row.BalanceChange}}</div>
						<div v-if="scope.row.BalanceChange>0">{{scope.row.BalanceChange}}</div>
						<div v-if="scope.row.BalanceChange<0" style="color:#F56C6C;">{{scope.row.BalanceChange}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="GiftBalanceChange" label="变更赠送金">
					<template slot-scope="scope">
						<div v-if="scope.row.GiftBalanceChange==0">{{scope.row.GiftBalanceChange}}</div>
						<div v-if="scope.row.GiftBalanceChange>0">{{scope.row.GiftBalanceChange}}</div>
						<div v-if="scope.row.GiftBalanceChange<0" style="color:#F56C6C;">{{scope.row.GiftBalanceChange}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="AllBalance" label="余额"></el-table-column>
				<el-table-column prop="OrderNo" label="关联单号">
					<template slot-scope="scope">
						<div style="cursor: pointer;color:#409EFF;" v-if='scope.row.RecordType == 2' @click="Pushtorder(scope.row)">{{scope.row.OrderNo}}</div>
						<div style="cursor: pointer;"  v-if='scope.row.RecordType == 3'>{{scope.row.OrderNo}}</div>
						<div style="cursor: pointer;color:#409EFF;"  v-if='scope.row.RecordType == 6' @click='Pushtorder(scope.row)'>{{scope.row.OrderSupportNo}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="变更时间"></el-table-column>
				<el-table-column label="备注" width='370px'>
					<template slot-scope="scope">
						<el-tooltip class="item-tooltip" popper-class="myaccout-note-detail-tooltip" effect="light" :content="scope.row.Remark"
						 placement="top-start">
							<div class="note-detail">
								{{scope.row.Remark}}
							</div>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
				</div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		enumList,
		memberBalanceRecordList,
		//2.1.2版本
		MemberBalanceRecordStatistics
	} from '@/api/TurnTomySelf'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				Cause: null,
				changeCauseList: [],
				startTime: '',
				endTime: '',
				groupData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 1,
				searchKey: '',
				loading: false,
				goUrls: config.GO_URL,
				exportUrl: config.EXPORT_URL,
				
				RecordStatistics:{}

			}
		},
		created() {
			//初始化变动原因
			this.getCauselist()
			this.getCurrentMonthFirst()
			this.getCurrentMonthLast()
			this.getList()
			this.getMemberBalanceRecordStatistics()
		},
		methods: {
			async getMemberBalanceRecordStatistics(){
				try{
					this.loading = true
					let data = {
						KeyWords: this.searchKey,
						RecordType: this.Cause,
						StartTime: this.startTime,
						EndTime: this.endTime,
					}
					let result = await MemberBalanceRecordStatistics(data)
					if(result.IsSuccess){
						this.RecordStatistics = result.Result
					}
				}catch(err){
					
				}finally{
					this.loading = false
				}
			},
			// 余额明细导出
			async exportFun() {
				try {
					let url = this.exportUrl + '/pc/memberBalance/memberBalanceRecordExport?' +
						'KeyWords=' + this.searchKey +
						'&RecordType=' + (this.Cause?this.Cause:'') +
						'&StartTime=' + (this.startTime ? this.startTime : '') +
						'&EndTime=' + (this.endTime ? this.endTime : '')
						// console.log(url,'导出的网址888')
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}

			},
			//2.3 消费   6退款
			Pushtorder(record) {
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				if (record.RecordType == 2 || record.RecordType == 3) {
					let OrderNoId = record.OrderNo ? record.OrderNo : 0
					let url = this.goUrls + headsUrls + '/order/orderDetailpage?Id=' + OrderNoId
					window.open(url)
				}
				if (record.RecordType == 6) {
					let OrderNoId = record.OrderSupportNo ? record.OrderSupportNo : 0
					let url = this.goUrls + headsUrls + '/order/refundAfterSale/refundDetails?id=' + OrderNoId
					window.open(url)
				}
			},
			async getCauselist() {
				try {
					let data = {
						EnumName: 'EnumMemberBalanceRecordType'
					}
					let result = await enumList(data)
					if (result.IsSuccess) {
						this.changeCauseList = result.Result
					}
				} catch (err) {

				} finally {

				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
				this.getMemberBalanceRecordStatistics()
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
				this.getMemberBalanceRecordStatistics()
			},
			async getList() {
				try {
					this.loading = true
					let data = {
						KeyWords: this.searchKey,
						RecordType: this.Cause,
						StartTime: this.startTime,
						EndTime: this.endTime,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await memberBalanceRecordList(data)
					if (result.IsSuccess) {
						this.groupData = result.Result.Results || [];
						this.Total = result.Result.Total;
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			getCurrentMonthFirst() {
				var date = new Date()
				date.setDate(1)
				var month = parseInt(date.getMonth() + 1)
				var day = date.getDate()
				if (month < 10) month = '0' + month
				if (day < 10) day = '0' + day
				this.startTime = date.getFullYear() + '-' + month + '-' + day + ' ' + "00:00:00"
			},
			getCurrentMonthLast() {
				const year = new Date().getFullYear()
				const month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
				const date = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
				const hh = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()
				const mm = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
				const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
				this.endTime = year + '-' + month + '-' + date + ' ' + hh + ':' + mm + ':' + ss
			},
			goodsSearch() {
				this.currentPage = 1
				this.getList()
				this.getMemberBalanceRecordStatistics()
			},
		}
	}
</script>

<style>
</style>
